<template>
  <button class="flex items-center justify-center icon-button" :disabled="disabled || loading" :class="classes">
    <PulseLoadingSpinner v-if="loading" />
    <component
      v-else
      :is="IconMap[icon]"
      :class="`h-[${iconSize}px] w-[${iconSize}px] fill-current`"
      v-hubble="'icon'"
    />
  </button>
</template>

<script setup lang="ts">
import { type PropType, computed } from 'vue';
// this is okay for now but with more apps we don't want Valet to be the source of icons, we'll want to move these to a shared location.
import * as Icons from '../icons';

const IconMap = {
  ...Icons,
};

const classMap = {
  primary: 'bg-primary-700 hover:bg-primary-800 active:bg-primary-900 text-neutral-white',
  secondary: 'bg-neutral-white hover:bg-neutral-100 active:bg-neutral-200 border-neutral-300 border !text-neutral-900',
  success: 'bg-success-700 hover:bg-success-800 active:bg-success-900 text-neutral-white',
  danger: 'bg-danger-700 hover:bg-danger-800 active:bg-danger-900 text-neutral-white',
  warning: 'bg-warning-700 hover:bg-warning-800 active:bg-warning-900 text-neutral-white',
  ghost: 'bg-neutral-white hover:bg-neutral-100 active:bg-neutral-200 text-neutral-900',
};

const props = defineProps({
  icon: {
    type: String as PropType<keyof typeof IconMap>,
    required: true,
  },
  variant: {
    type: String as PropType<keyof typeof classMap>,
    default: 'primary',
  },
  shape: {
    type: String as PropType<'square' | 'rounded' | 'roundedFull'>,
    default: () => 'rounded',
  },
  size: {
    type: String as PropType<'large' | 'medium' | 'default'>,
    default: () => 'default',
  },
  iconSize: {
    type: Number,
    default: 14,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  const shapeStyle = props.shape === 'roundedFull' ? 'rounded-full' : props.shape === 'rounded' ? 'rounded-lg' : '';

  const base = {
    [classMap[props.variant]]: true,
    ...{ [shapeStyle]: true },
    'h-6 w-6': props.size === 'default',
    'h-7 w-7': props.size === 'medium',
    'h-[36px] w-[36px]': props.size === 'large',
  };

  if (props.disabled) {
    if (props.variant === 'ghost') {
      return {
        ...base,
        '!text-neutral-400 !cursor-not-allowed': true,
      };
    }

    return {
      ...base,
      '!bg-neutral-300 !text-typography-disabled !cursor-not-allowed': true,
    };
  }

  return base;
});
</script>

<style lang="scss" scoped>
.icon-button {
  :deep(svg) {
    fill: currentColor;
  }
}
</style>
